import { isEmpty } from "lodash";
import Archive from "vue-material-design-icons/Archive.vue";
import ArchiveCheck from "vue-material-design-icons/ArchiveCheck.vue";
import ArchiveRemove from "vue-material-design-icons/ArchiveRemove.vue";
import ArchiveCheckOutline from "vue-material-design-icons/ArchiveCheckOutline.vue";
import PackageVariantClosedCheck from "vue-material-design-icons/PackageVariantClosedCheck.vue";
import PackageVariantClosedRemove from "vue-material-design-icons/PackageVariantClosedRemove.vue";

// Available types of scanned counts
export const SCANNED_COUNTS_TYPES = {
  PARCELS: "parcels",
  INVENTORY: "inventory"
};

// Property types for counts (total, successful, failed)
const PROP_TYPES = {
  TOTAL: "total",
  PACKETS_COUNT: "packets_count",
  SUCCESSFUL: "successful",
  FAILED: "failed"
};

// Order of prop types for PARCELS counts
const PARCELS_COUNTS_ORDER = [
  PROP_TYPES.FAILED,
  PROP_TYPES.SUCCESSFUL,
  PROP_TYPES.PACKETS_COUNT
];

// Order of prop types for INVENTORY counts
const INVENTORY_COUNTS_ORDER = [
  PROP_TYPES.TOTAL,
  PROP_TYPES.SUCCESSFUL,
  PROP_TYPES.FAILED
];

// Text colors associated with each property type and default fallback
const TEXT_COLORS = {
  [PROP_TYPES.TOTAL]: "dark-gray",
  [PROP_TYPES.PACKETS_COUNT]: "green",
  [PROP_TYPES.SUCCESSFUL]: "green",
  [PROP_TYPES.FAILED]: "red",
  default: "dark-gray"
};

// Icons for each prop type of PARCELS counts
const PARCELS_COUNTS_ICONS = {
  [PROP_TYPES.PACKETS_COUNT]: ArchiveCheckOutline,
  [PROP_TYPES.SUCCESSFUL]: PackageVariantClosedCheck,
  [PROP_TYPES.FAILED]: PackageVariantClosedRemove
};

// Icons for each prop type of INVENTORY counts
const INVENTORY_COUNTS_ICONS = {
  [PROP_TYPES.TOTAL]: Archive,
  [PROP_TYPES.SUCCESSFUL]: ArchiveCheck,
  [PROP_TYPES.FAILED]: ArchiveRemove
};

// Returns count object with icon, color and count number for specific prop type
const getCount = (type, prop, count) => {
  if (!Object.values(SCANNED_COUNTS_TYPES).includes(type)) return;

  const icons =
    type == SCANNED_COUNTS_TYPES.PARCELS
      ? PARCELS_COUNTS_ICONS
      : INVENTORY_COUNTS_ICONS;

  return {
    icon: icons[prop] || null,
    color: TEXT_COLORS[prop] || TEXT_COLORS.default,
    value: count
  };
};

// Returns the order of prop types for each type of scanned counts
const getOrder = type => {
  switch (type) {
    case SCANNED_COUNTS_TYPES.PARCELS:
      return PARCELS_COUNTS_ORDER;
    case SCANNED_COUNTS_TYPES.INVENTORY:
      return INVENTORY_COUNTS_ORDER;
    default:
      return Object.values(PROP_TYPES);
  }
};

// Maps the original counts object to a constructed array of count objects
// based on provided type
export const getScannedCounts = (type, counts) => {
  if (isEmpty(counts)) return null;

  const order = getOrder(type);

  return order.map(prop => {
    return getCount(type, prop, counts[prop]);
  });
};
